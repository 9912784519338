<template>
  <div>
    <el-dialog
      title="Story Detail"
      :visible="isShow"
      @close="close"
      :before-close="close"
      width="50%"
      :close-on-click-modal="onClose"
    >
      <div class="panel payments-container">
        <el-form
          :model="detailData"
          label-width="120px"
          label-position="top"
          size="mini"
        >
          <el-form-item>
            <img :src="detailData.story_url" style="width:150px;" />
          </el-form-item>
          <el-form-item label="Story name">
            <el-input
              v-model="detailData.story_name"
              style="width:50%;"
              size="large"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">Хаах</el-button>
        <el-button @click="save(detailData.story_id)" type="success"
          >Хадгалах</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import services from "../../helpers/services";
export default {
  props: {
    isShow: Boolean,
    id: String,
    close: Function
  },
  created() {
    this.getOneStory();
  },
  methods: {
    async getOneStory() {
      await services.getOneStory(this.id).then(res => {
        if (res.data.status === "success") {
          this.detailData = res.data.data;
        }
      });
    },
    async save(id) {
      await services.getUpdateStory(id, this.detailData).then(res => {
        if (res.data.status === "success") {
          this.$message({
            type: "success",
            message: "Амжилттай хадгаллаа..."
          });
          this.close();
        } else {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
      });
    }
  },
  data() {
    return {
      onClose: false,
      isVisibleDetail: false,
      detailData: {}
    };
  }
};
</script>
