<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Story
      </h3>
    </div>
    <div class="panel" style="width:70%; margin-left: 15%;">
      <el-row :gutter="5">
        <el-col :span="12">
          <el-input v-model="search" size="medium" placeholder="Хайх нэр" />
        </el-col>
        <el-col :span="5">
          <el-select v-model="type" size="medium">
            <el-option
              v-for="(type, index) in storyType"
              :key="index"
              :label="type.name"
              :value="type.name"
            />
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select v-model="status" size="medium">
            <el-option
              v-for="(status, index) in storyStatus"
              :key="index"
              :label="status.name"
              :value="status.name"
            />
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button @click="getAllStory" size="medium" type="primary"
            >Хайх</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="storyList" v-loading="loading" style="width: 100%">
        <el-table-column prop="story_url" width="150px" label="Зураг">
          <template slot-scope="scope">
            <img :src="scope.row.story_url" alt="" class="image" />
          </template>
        </el-table-column>
        <el-table-column prop="story_name" label="Стори нэр"> </el-table-column>
        <el-table-column prop="sort" label="Эрэмбэ"> </el-table-column>
        <el-table-column align="right" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="success"
              @click="showDetail(scope.row.story_id)"
              >Засах</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="deleteStory(scope.row.story_id)"
              >Устгах</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <story-detail
      v-if="isVisible"
      :isShow="isVisible"
      :id="storyId"
      :close="close"
    ></story-detail>
  </div>
</template>
<script>
import storyDetail from "./StoryDetail.vue";
import service from "../../helpers/services";
export default {
  components: {
    storyDetail
  },
  created() {
    this.getAllStory();
  },
  methods: {
    async getAllStory() {
      this.loading = true;
      const payload = {
        type: this.type,
        size: 10,
        from: 0,
        status: this.status
      };
      await service.getAllStory(payload).then(res => {
        if (res.data.status === "success") {
          this.storyList = res.data.data;
          this.loading = false;
        }
      });
    },
    async deleteStory(id) {
      this.$confirm("Та энэ стори-г устгах гэж байна?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(async () => {
          await service.getDeleteStory(id).then(res => {
            if (res.data.status === "success") {
              this.$message({
                type: "success",
                message: res.data.message
              });
              this.getAllStory();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    },
    showDetail(id) {
      this.storyId = id;
      this.isVisible = true;
    },
    close() {
      this.getAllStory();
      this.isVisible = false;
    }
  },
  data() {
    return {
      storyStatus: [{ name: "active" }, { name: "expired" }],
      storyType: [{ name: "story" }, { name: "highlight" }],
      storyList: [],
      isVisible: false,
      storyId: null,
      loading: false,
      search: "",
      status: "active",
      type: "story"
    };
  }
};
</script>
<style scoped>
.image {
  width: 120px;
  height: 100px;
}
</style>
